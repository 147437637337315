import { tw } from '@/utils/tw';
import ContactEditorialTheme from 'base/components/ContactEditorial/theme';

const { TextField, TextArea, Dialog, Checkbox } = ContactEditorialTheme;

const ContactEditorial = tw.theme({
  extend: ContactEditorialTheme,
  slots: {
    base: ['bg-linen-200', 'md:p-11', 'py-6', 'px-4'],
    headline: ['text-center', 'md:text-headline-lg', 'text-headline-md'],
    caption: ['text-center', 'md:text-content-sm', 'text-content-xs'],
  },
});

export default Object.assign(ContactEditorial, {
  Checkbox,
  Dialog,
  TextArea,
  TextField,
});
