/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "image": {
      "size_default": "min-w-25 sm:min-w-37.5"
    }
  },
  "swiper": {},
  "disclaimer_line_variant_default": "relative top-5.5 md:top-8",
  "disclaimer_line_colors_default": "",
  "disclaimer_line_size_default": "h-0",
  "disclaimer_colors_default": "text-gray-600",
  "disclaimer_size_default": "text-preamble-xs md:text-preamble-sm pr-3",
  "disclaimer_variant_default": "",
  "title_variant_default": "",
  "title_colors_default": "",
  "title_size_default": "text-content-xs mt-1 md:mt-3",
  "price_variant_default": "!font-bold",
  "price_colors_default": "",
  "price_size_default": "text-content-xs md:pt-0.5",
  "colors_default": "bg-white wings-white",
  "size_default": "w-screen ml-[-50vw] md:w-full md:ml-0 md:my-6 p-6",
  "variant_default": "relative left-1/2 md:left-0 wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AffiliateGallery");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;