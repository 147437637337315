import { tw } from '@/utils/tw';
import BaseBylineTheme from 'base/components/Byline/theme';

const { Image } = BaseBylineTheme;

const Byline = tw.theme({
  extend: BaseBylineTheme,
  slots: {
    base: `italic`,
    text: `text-black`,
    author: `uppercase`,
  },
});

export default Object.assign(Byline, { Image });
