import { tw } from '@/utils/tw';
import BaseFactBox from 'base/components/FactBox/theme';

const { Prose } = BaseFactBox;

const FactBox = tw.theme({
  extend: BaseFactBox,
  slots: {
    base: [
      'relative',
      'my-6',
      'sm:p-7.5',
      'bg-linen-300',
      'before:w-full',
      'before:h-full',
      'before:block',
      'before:absolute',
      'before:left-0',
      'before:bottom-0',
      'before:bg-[url("/image/factbox-background-blob.svg")]',
      'before:bg-cover',
      'before:bg-center',
      'before:pointer-events-none',
    ],
    headline: 'normal-case italic text-headline-md',
  },
});

export default Object.assign(FactBox, { Prose });
