import { tw } from '@/utils/tw';
import BaseAffiliateItemTheme from 'base/components/AffiliateItem/theme';

const { Image } = BaseAffiliateItemTheme;

const AffiliateItem = tw.theme({
  extend: BaseAffiliateItemTheme,
  slots: {
    base: `border-b border-gray-100 my-3`,
    counter: `text-gray-500 text-headline-3xl md:text-headline-3xl`,
    headline: `text-content-lg md:text-content-lg`,
    subtitle: `text-content-sm md:text-content-sm`,
    description: `text-body-sm md:text-body-lg`,
    productVendor: `text-content-xs md:text-content-xs text-gray-700`,
    productHeadline: `text-content-md md:text-content-md`,
    productCaption: `text-summer-red-600 text-body-sm md:text-body-sm`,
  },
  variants: {
    multiple: {
      false: {
        base: `py-6`,
      },
      true: {
        base: `py-6 md:gap-x-6 md:gap-y-4`,
      },
    },
  },
});

export default Object.assign(AffiliateItem, { Image });
