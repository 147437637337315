import { tw } from '@/utils/tw';
import { default as BaseShowHero } from 'base/components/ShowHero/theme';

const { Tag } = BaseShowHero;

const ShowHero = tw.theme({
  extend: BaseShowHero,
  slots: {
    base: 'to-linen-100 from-white',
    content: `gap-4`,
    category: '-mb-3',
    headline: 'text-headline-lg md:text-headline-2xl',
    description: 'text-preamble-sm',
  },
});

export default Object.assign(ShowHero, { Tag });
