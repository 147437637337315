import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import NotAMemberModalLogo from '@/femina/public/image/femina-logo-red.svg';
import useUserAuth from '@/hooks/useUserAuth';
import { mergeProps } from '@/utils/mergeProps';
import { StandaloneSaveArticleButton } from 'base/components/SaveArticleButton';

export const FeminaStandaloneSaveArticleButton: typeof StandaloneSaveArticleButton = (props) => {
  const { logInUrl, signUpUrl } = useUserAuth();

  return (
    <StandaloneSaveArticleButton
      {...mergeProps(
        {
          options: { $button: { size: 'large' } },
          modalContent: (
            <div className="relative flex flex-col-reverse items-center md:flex-row">
              <Icon options={{ size: 'medium', className: 'cursor-pointer absolute right-2 top-2' }} name="close" />
              <div className="bg-linen-200 flex h-70 w-full items-center justify-center md:h-90 md:w-106">
                <NotAMemberModalLogo className="w-1/2" />
              </div>
              <div className="flex max-w-90 flex-col items-center gap-y-2 px-6 pb-5 pt-10 md:gap-y-3 md:px-10 md:py-0">
                <span className="mb-1 text-center text-preamble-lg">
                  För att spara den här artiklen måste du vara inloggad
                </span>
                <Button link={{ href: logInUrl, options: { rel: 'nofollow' } }} content="Logga in på ditt konto" />
                <span className="text-preamble-md">eller</span>
                <Button
                  link={{ href: signUpUrl, options: { rel: 'nofollow' } }}
                  options={{ colors: 'secondary' }}
                  content="Skapa ett konto"
                />
              </div>
            </div>
          ),
        },
        props,
      )}
    />
  );
};
