import { tw } from '@/utils/tw';
import BaseArticleTeaserBodytextTheme from 'base/components/ArticleTeaser/Bodytext/theme';

const { Image } = BaseArticleTeaserBodytextTheme;

const ArticleTeaserBodytext = tw.theme({
  extend: BaseArticleTeaserBodytextTheme,
  slots: {
    category: `hidden`,
    content: `mb-6`,
    description: `md:text-headline-md`,
    footer: `md:text-headline-md`,
  },
  variants: {
    variant: {
      fourth: {
        content: `bg-linen-200 shadow-linen-300 shadow-[4px_4px_0px_1px]`,
      },
    },
  },
  defaultVariants: {
    variant: 'fourth',
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
