import { tw } from '@/utils/tw';
import BaseArticleDateTheme from 'base/components/ArticleDate/theme';

const ArticleDate = tw.theme({
  extend: BaseArticleDateTheme,
  slots: {
    date: `text-content-sm text-gray-600 before:relative before:-top-0.5 before:pr-1.5 before:content-['|']`,
    updatedDate: `text-content-sm text-gray-600 before:relative before:-top-0.5 before:pl-1.5 before:content-['|']`,
  },
});

export default ArticleDate;
