/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { FeminaLabradorFactBox as ResolvedLabradorFactBox } from 'sites/femina/components/labrador/FactBox';

export const LabradorFactBox: typeof ResolvedLabradorFactBox = withLabradorInstanceof((props) => {
    return <ResolvedLabradorFactBox {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorFactBoxProps = PropsFromComponent<typeof LabradorFactBox>;
