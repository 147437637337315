import { Quotebox } from '@/components/Quotebox';
import QuoteboxBlob from '@/femina/public/image/quotebox-blob.svg';
import { LabradorComponent } from '@/types/component';

export const FeminaLabradorQuotebox: LabradorComponent = ({ type, meta, data }) => {
  return (
    <Quotebox
      data-content-index={meta.contentIndex}
      author={data.author}
      quote={data.quote}
      quotationMark={<QuoteboxBlob className="mx-auto h-[29px] w-[50px]" />}
    />
  );
};
