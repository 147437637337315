/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "quote_variant_primary": "italic relative",
  "quote_variant_secondary": "italic relative",
  "quote_size_default": "text-headline-lg md:text-headline-2xl pt-3",
  "quote_colors_default": "",
  "quotationMark_variant_primary": "inline-block",
  "quotationMark_variant_secondary": "",
  "quotationMark_size_default": "mb-4",
  "quotationMark_colors_default": "",
  "author_variant_primary": "inline-block",
  "author_variant_secondary": "inline-block",
  "author_size_default": "text-body-lg mt-3",
  "author_colors_default": "",
  "variant_primary": "text-center",
  "variant_secondary": "text-center",
  "size_default": "w-full p-6",
  "colors_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Quotebox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;