import { cn } from '../cn';

type BackgroundConfig = { desktop?: string; mobile?: string };

/**
 * Sets different text colors for background colors
 *
 * Dynamic class names used here (also with `sm:` prefix) should be added to the `safelist` in tailwind config
 *
 * @param config Config with `desktop` and `mobile` properties for background color from Labrador
 * @param settings An object with keys of background colors and values of text colors
 * @returns {string} Returns classes for text color
 */
export const withTextColorForBackground = (config: BackgroundConfig, settings: Record<string, string>) => {
  const { desktop, mobile } = config ?? {};

  if (!desktop && !mobile) {
    return null;
  }

  const mobileTextColor = settings[mobile ?? ''];

  const desktopTextColor =
    (mobileTextColor || settings[desktop ?? '']) && `sm:${settings[desktop ?? ''] ?? 'text-inherit'}`;

  return cn(mobileTextColor, desktopTextColor);
};
