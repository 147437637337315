import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: `h-[70px] md:h-[45px]`,
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      primary: {
        base: `bg-linen-200 h-[70px] md:h-[45px]`,
        headline: `text-preamble-sm md:text-preamble-sm text-black`,
        label: `text-preamble-sm md:text-preamble-sm !font-extrabold text-black`,
      },
    },
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
