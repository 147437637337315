import { FactBox } from '@/components/FactBox';
import FactBoxHeadlineBlob from '@/femina/public/image/factbox-headline-blob.svg';
import { LabradorComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { withBackgroundColor } from '@/utils/withBackgroundColor';
import { withTextColorForBackground } from '@/utils/withTextColorForBackground';
import { renderContentComponent } from 'modules/dynamic/components';

export const FeminaLabradorFactBox: LabradorComponent = ({ meta, data, descendants }) => {
  const images = descendants.map(renderContentComponent);

  const backgroundColor = withBackgroundColor(meta.backgroundColor) ?? 'bg-linen-300';
  const textColor =
    withTextColorForBackground(meta.backgroundColor, {
      'bg-royal-blue': 'text-white',
      'bg-fe-royal-blue-700': 'text-white',
      'bg-secondary': 'text-white',
      'bg-black': 'text-white',
      // TODO: Remove after removing factbox calico colors from labrador
      'bg-accent-1': 'text-white',
    }) ?? 'text-black';

  return (
    <FactBox
      headline={
        data.title && (
          <>
            <FactBoxHeadlineBlob className="mb-1.5 h-2.5 w-[31px]" />
            {data.title}
          </>
        )
      }
      markup={data.bodytext}
      data-content-index={meta.contentIndex}
      elements={images}
      options={{ className: cn(backgroundColor, textColor) }}
    />
  );
};
