import { tw } from '@/utils/tw';
import TextAreaTheme from 'base/components/TextArea/theme';

const { Caption } = TextAreaTheme;

const TextArea = tw.theme({
  extend: TextAreaTheme,
  slots: {
    label: `group-focus-within:!text-secondary-700 text-gray-800 group-hover:text-black group-data-[status=error]:text-red-700`,
    input: `bg-white outline outline-0 outline-offset-0 outline-linen-300 ring-1 ring-inset ring-gray-400 text-content-sm placeholder:text-gray-700 group-data-[status=error]:outline-error-200 group-data-[status=error]:ring-2 group-data-[status=error]:ring-inset group-data-[status=error]:ring-error-500 hover:ring-black hover:placeholder:text-black hover:not-focus:outline-0 focus:outline-4 focus:ring-black focus:placeholder:text-black`,
  },
  variants: {
    variant: {
      primary: {
        label: `text-input-sm`,
        input: `h-10 peer-[.absolute]/icon:pr-7`,
      },
      secondary: {
        label: `text-content-sm group-focus-within:text-content-xs group-data-[filled]:text-content-xs`,
      },
    },
  },
});

export default Object.assign(TextArea, { Caption });
