import { tw } from '@/utils/tw';
import BaseSavedArticlesTheme from 'base/components/SavedArticles/theme';

const Tag = tw.theme({
  extend: BaseSavedArticlesTheme.Tag,
  variants: {
    colors: { text: `text-summer-red-600` },
    variant: { text: `uppercase` },
  },
});

const Icon = tw.theme({
  base: `text-gray-400 hover:text-gray-600`,
  extend: BaseSavedArticlesTheme.Icon,
});

const Image = tw.theme({
  extend: BaseSavedArticlesTheme.Image,
});

const SavedArticles = tw.theme({
  extend: BaseSavedArticlesTheme,
  slots: {
    headline: `text-headline-md after:hidden md:text-headline-lg`,
    empty: `col-span-4 mt-3 gap-3 text-preamble-sm md:text-preamble-sm`,
    articleCaption: `pt-2 text-headline-md md:text-headline-md`,
    articleDescription: `pt-1.5 text-preamble-sm md:text-preamble-sm`,
  },
});

export default Object.assign(SavedArticles, { Tag, Icon, Image });
