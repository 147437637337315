import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorArticleTeaserDefault } from 'base/components/labrador/ArticleTeaser/Default';

export const FeminaLabradorArticleTeaserDefault: LabradorComponent = (props) => {
  return (
    <LabradorArticleTeaserDefault
      {...mergeProps(
        {
          data: {
            options: { variant: props.meta.isArticleCard ? 'card' : undefined },
          },
        },
        props,
      )}
    />
  );
};
