/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "caption_colors_default": "before:bg-[url('/image/swoosh-long-red.svg')]",
    "caption_size_default": "text-preamble-sm md:text-preamble-lg before:mt-5 before:mb-3 before:h-1.25 before:w-[105px] before:mx-auto",
    "caption_variant_default": "before:block before:bg-no-repeat"
  },
  "swiper": {
    "arrow_colors_default": "text-gray-500",
    "navigation_item_colors_default": "bg-linen-300 data-[active=true]:bg-gray-900",
    "title_colors_default": "after:bg-linen-300 bg-linen-100",
    "title_size_default": "text-headline-lg md:text-headline-xl after:h-px mb-5 after:ml-3 px-6 md:px-0 md:-mx-20",
    "title_variant_default": "flex items-center break-words after:block after:flex-grow",
    "content_size_default": "md:px-20"
  },
  "colors_default": "bg-linen-100 wings-linen-100",
  "size_default": "py-3 md:py-6 w-screen -ml-[calc(50vw-50%)] md:w-full md:ml-0",
  "variant_default": "relative wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "BlogSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;