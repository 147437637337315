import { tw } from '@/utils/tw';
import RadioButtonTheme from 'base/components/RadioButton/theme';

const RadioButton = tw.theme({
  extend: RadioButtonTheme,
  slots: {
    base: ['flex', 'items-center', 'my-4', 'relative'],
    input: [
      '[&:not(:checked)]:hover:shadow-linen-300',
      'active:bg-gray-100',
      'active:shadow-none',
      'appearance-none',
      'bg-clip-content',
      'border-1',
      'border-black',
      'checked:after:absolute',
      'checked:after:bg-black',
      'checked:after:bg-black',
      'checked:after:block',
      'checked:after:h-2.5',
      'checked:after:left-1.5',
      'checked:after:rounded-full',
      'checked:after:top-1.75',
      'checked:after:w-2.5',
      'checked:border-1',
      'checked:border-black',
      'checked:hover:shadow-none',
      'focus-visible:shadow-linen-300',
      'focus:outline-none',
      'focus:shadow-4',
      'focus:shadow-linen-300',
      'h-5.5',
      'hover:shadow-4',
      'rounded-full',
      'text-black',
      'w-5.5',
    ],
    label: ['ml-2.5', 'text-headline-xs'],
  },
});

export default RadioButton;
