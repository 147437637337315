import { mergeProps } from '@/utils/merge';
import { StandaloneBlogSlider } from 'base/components/BlogSlider';
import { useAppState } from 'lib/hooks/useAppState';

const breakpoints = {
  360: {
    slidesPerView: 2,
  },
  560: {
    slidesPerView: 3,
  },
  960: {
    slidesPerView: 4,
  },
  1200: {
    slidesPerView: 5,
  },
};

export const FeminaStandaloneBlogSlider: typeof StandaloneBlogSlider = (props) => {
  const [{ device }] = useAppState();

  return (
    <StandaloneBlogSlider
      {...mergeProps(
        {
          options: {
            $swiper: {
              spaceBetween: device === 'desktop' ? 80 : 40,
              breakpoints,
            },
            $slide: {
              $image: {
                className: `aspect-square rounded-full`,
              },
            },
          },
        },
        props,
      )}
    />
  );
};
